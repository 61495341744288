import './topbar.scss';
import { SocialIcon } from 'react-social-icons';

export default function Topbar({ menuOpen, setMenuOpen }) {
    return (
        <div className={'topbar ' + (menuOpen && 'active')}>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className='logo'>Ashish</a>
                    <div className="itemContainer">           
                    <SocialIcon
						url='https://www.linkedin.com/in/ashish-goomar/'
						target='_blank'
                        className='icon'
                        fgColor='#fff'
						style={{ height: 35, width: 35 }}
					/>
                    </div>
                    <div className="itemContainer">
					<SocialIcon
						url='https://github.com/agoomar'
						className='icon'
						target='_blank'
						fgColor='#fff'
						style={{ height: 35, width: 35 }}
					/>
                    </div>
                </div>
                <div className="right">
                    <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                        <span className='line1'></span>
                        <span className='line2'></span>
                        <span className='line3'></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
