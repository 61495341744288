export const featuredPortfolio = [
	{
		id: 1,
		title: 'Cloud School',
		img: 'http://adigaskell.org/wp-content/uploads/2014/11/learn-cloud.jpg',
		linkUrl: 'https://github.com/Cloud-Schoolz/frontend',
	},
	{
		id: 2,
		title: 'React PC',
		img: 'https://g.foolcdn.com/editorial/images/632647/earnings-hot-stocks.jpg',
		linkUrl: 'https://github.com/agoomar/react-pc',
	},
	{
		id: 3,
		title: 'Food Truck',
		img: 'https://bloximages.newyork1.vip.townnews.com/americanpress.com/content/tncms/assets/v3/editorial/d/2a/d2a78f68-365b-11eb-88e2-ffb6e4151995/5fca7ba679c97.image.jpg',
		linkUrl: 'https://github.com/agoomar/U4BuildWeek',
	},
	{
		id: 4,
		title: 'Hulu-2.0',
		img: 'https://is1-ssl.mzstatic.com/image/thumb/Purple125/v4/cf/63/33/cf63335d-b46e-14bb-d78b-548e576faac8/HuluAppIcon-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png',
		linkUrl: 'https://hulu-2-0-rosy.vercel.app/',
	},
];
