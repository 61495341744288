import { useState, useEffect } from 'react';
import PortfolioList from '../portfolioList/PortfolioList';
import './portfolio.scss'
import { featuredPortfolio } from '../../data.js'

export default function Portfolio() {

        const [selected, setSelected] = useState('featured');
        const [data, setData] = useState([]);

        const list = [
            {
                id: 'featured',
                title: 'My Projects',
            },
            // {
            //     id: 'web',
            //     title: 'Web App',
            // },
            // {
            //     id: 'mobile',
            //     title: 'Mobile App',
            // },
            // {
            //     id: 'design',
            //     title: 'Design',
            // },
            // {
            //     id: 'content',
            //     title: 'Content',
            // },
        ];

        useEffect(() => {
           
           switch(selected) {
               case 'featured':
                   setData(featuredPortfolio);
                   break;
                   default:
                       setData(featuredPortfolio)
           } 

        }, [selected])

    return (
        <div className='portfolio' id='portfolio'>
            <h1>Portfolio</h1>
            <ul>
                {list.map(item => (
                    <PortfolioList 
                    title={item.title} 
                    active={selected === item.id} 
                    setSelected={setSelected} 
                    id={item.id}
                    />
                ))}
            </ul>

            <div className="container">
                {data.map((d) => (
                    <a href={d.linkUrl} rel='noreferrer' target='_blank'>
                    <div className="item">
                        <img 
                        src={d.img}
                        alt="" 
                        />
                        <h3>{d.title}</h3>
                    </div>
                    </a>
                ))}
            </div>
        </div>
    )
}
