import './contact.scss'

export default function Contact() {
    return (
        <div className='contact' id='contact'>
            <div className='wrapper'>
                <h1>About Me</h1>
                <p>I'm Ashish Goomar and I'm a full stack software engineer, graduated from Lambda School which is a six month coding boot camp that teaches everything from front-end development (HTML, JavaScript, CSS, React, Redux, Next.js) to back-end development (Node.js, Express, Middleware, PostgreSQL). I am always excited to learn more about what is new in the development world and to hone my skills as a developer.</p>
            </div>
        </div>
    )
}
